@charset "utf-8";

@import "so-simple";

*:focus {outline:none !important}

input, textarea {
  display: block;
  width: 70%;
  margin: 10px 0;
  padding: 10px;
}

input[type="text"], textarea {
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #eee;
  transition: .3s border-color;
}

input[type="text"]:hover, textarea:hover {
  border: 1px solid #aaa;
}


button {

  padding:10px;
  // margin: 10px;
  border:none;
  background-color:#000;
  color:#fff;
  border-radius:5px;
  transition: all .3s;

}

button:hover {
  background-color:#404040;
  color: #bfbfbf;
}
